<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-grid @click="setOpenCompanySelection(true)" class="top-content">
          <ion-row class="ion-align-items-center">
            <ion-col size="auto">
              <!-- <ion-thumbnail v-if="!isDefaultImage">
              <ion-img :src="photoUrl"></ion-img>
            </ion-thumbnail> -->
              <div
                class="image-default"
                :style="`background: ${avatarTheme.background}; color:${avatarTheme.color}`"
              >
                {{ company?.name?.slice(0, 1) || company?.branch?.slice(0, 1) }}
              </div>
            </ion-col>
            <ion-col>
              <ion-row class="ion-align-items-center">
                <ion-text class="label-card mr-1 fs-2 mb-1">{{ company?.name || company?.branch }}</ion-text>
              </ion-row>
              <ion-row class="ion-align-items-center">
                <span class="label-card text-gray fw-500 mr-1 mb-1">{{ company?.account_number ?? '' }}</span>
              </ion-row>
              <ion-row class="ion-align-items-center" v-if="company?.black_list">
                <div class="box-label d-flex align-center">
                  <ion-icon size="small" :icon="banOutline" color="danger"></ion-icon>
                  <span class="text-danger ml-1 fs-2 fw-500">{{ $t('black_listed') }}</span>
                </div>
              </ion-row>
            </ion-col>
            <ion-col size="1">
              <ion-row>
                <ion-icon size="large" color="primary" :icon="chevronDownOutline"></ion-icon>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="flex px-2">
          <ion-buttons class="mr-2" slot="start">
            <ion-back-button
              color="primary"
              mode="ios"
              text=""
              default-href="/b2b/account"
              @click="goBack"
              :icon="chevronBackOutline"
            ></ion-back-button>
          </ion-buttons>
          <ion-label class="fw-600" @click="goBack">{{ $t('all_invoices') }}</ion-label>
        </div>
        <div class="flex px-4 checkbox" v-if="isRenderInvoices">
          <checkbox :checked="checkedAll" @onToggle="onSelectAllInvoices" />
          <ion-label>{{ $t('select_all') }}</ion-label>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <not-found-invoices v-if="isNotFoundInvoices" @browseProduct="goBrowseProduct" />
      <banner-info
        v-if="isRenderInvoices"
        :totalUnpaidInvoices="totalUnpaidInvoices"
        :unpaidAmount="unpaidAmount"
        :currencySymbol="currencySymbol"
        :availableCreditAmount="availableCreditAmount"
      />
      <div v-if="isRenderInvoices">
        <card-invoice
          v-for="invoice in cardInvoices"
          :key="invoice.id"
          :invoice="{ ...invoice, tenantName }"
          :customerId="company.id"
          @onSelection="onSelectSingleInvoice"
          @onEnterViewInvoice="onEnterViewInvoice(invoice)"
        />
      </div>
      <skeleton-card v-else-if="isLoadingSkeleton" />
      <ion-infinite-scroll
        @ionInfinite="loadMore($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabledLoadMore"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>

    <bottom-content
      v-if="isRenderInvoices"
      :currencySymbol="currencySymbol"
      :totalInvoiceAmount="totalInvoiceAmount"
      :totalSelectedInvoices="totalSelectedInvoices"
      @handle-pay-invoices="handlePayInvoices"
    />
    <skeleton-bottom v-else-if="isLoadingSkeleton" />
    <ion-modal
      :is-open="isOpenModalInfoRef"
      css-class="modal-info custom-modal"
      @didDismiss="setOpenModalInfo(false)"
    >
      <modal-content-info :title="modalInfoTitle" :content="modalInfoContent" @close-modal="closeModalInfo">
      </modal-content-info>
    </ion-modal>
    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="isOpenCompanySelection"
      @didDismiss="setOpenCompanySelection(false)"
    >
      <BranchSelection @closeModal="refreshAllData" />
    </ion-modal>
    <ion-modal mode="ios" :is-open="isOpenViewInvoice" @didDismiss="setOpenViewInvoice(false)">
      <view-invoice
        :currencySymbol="currencySymbol"
        :invoiceId="invoiceSelected.id"
        @openPaymentHistory="onEnterPaymentHistory"
        @close-page="setOpenViewInvoice(false)"
      />
    </ion-modal>
    <ion-modal mode="ios" :is-open="isOpenPaymentHistory" @didDismiss="setOpenPaymentHistory(false)">
      <payment-history
        @closeModal="setOpenPaymentHistory(false)"
        :data="paymentHistories"
        :currencySymbol="currencySymbol"
      />
    </ion-modal>
  </ion-page>
</template>
<script>
// packages
import { handleRamdomBackground } from '@/modules/b2b/services/libs/helper';
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import { useBackButton } from '@ionic/vue';
import { banOutline, chevronBackOutline, chevronDownOutline } from 'ionicons/icons';
import { defineComponent, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';

// components
import BranchSelection from '@/modules/sale/views/customer/BranchSelection.vue';
import ViewInvoice from '@/modules/sale/views/order/components/Invoice.vue';
import ModalContentInfo from '@/modules/sale/views/shared/ModalContentInfo.vue';
import {
  BannerInfo,
  BottomContent,
  Checkbox,
  NotFoundInvoices,
  PaymentHistory,
  SkeletonBottom,
  SkeletonCard
} from '@/modules/shared/components/invoices-payment';
import CardInvoice from './components/CardInvoice.vue';

const { mapGetters, mapActions } = createNamespacedHelpers('sale/payment');

export default defineComponent({
  name: 'select-invoices-sale',
  inject: ['$storage'],
  components: {
    BottomContent,
    CardInvoice,
    Checkbox,
    ModalContentInfo,
    SkeletonCard,
    SkeletonBottom,
    BranchSelection,
    BannerInfo,
    NotFoundInvoices,
    PaymentHistory,
    ViewInvoice
  },
  setup() {
    const noLoadItems = ref(false);
    const isOpenCompanySelection = ref(false);
    const router = useRouter();
    const isOpenRefLoading = ref(false);
    const setOpenLoading = (state) => (isOpenRefLoading.value = state);
    const goBrowseProduct = () => {
      router.push({ path: '/sale/main/home' }, () => {}, { replace: true });
    };
    const goBack = () => {
      const stateBackPath = router.options.history.state.back;
      nextTick(() => {
        stateBackPath === '/sale/account' ||
        stateBackPath === '/sale/select-customer' ||
        stateBackPath === '/sale/invoices/select-invoices' ||
        stateBackPath === '/sale/invoices/select-payment'
          ? router.push({ path: '/sale/account' }, () => {}, { replace: true })
          : router.back();
      });
    };
    useBackButton(10, async () => {
      await goBack();
    });
    const isOpenModalInfoRef = ref(false);
    const setOpenModalInfo = (state) => (isOpenModalInfoRef.value = state);
    const setOpenCompanySelection = (state) => (isOpenCompanySelection.value = state);
    const avatarTheme = handleRamdomBackground();
    const checkedAll = ref(true);
    const isOpenViewInvoice = ref(false);
    const setOpenViewInvoice = (state) => (isOpenViewInvoice.value = state);
    const isOpenPaymentHistory = ref(false);
    const setOpenPaymentHistory = (state) => (isOpenPaymentHistory.value = state);
    return {
      isOpenRefLoading,
      setOpenLoading,
      chevronBackOutline,
      chevronDownOutline,
      banOutline,
      goBrowseProduct,
      goBack,
      isOpenModalInfoRef,
      setOpenModalInfo,
      noLoadItems,
      isOpenCompanySelection,
      setOpenCompanySelection,
      avatarTheme,
      checkedAll,
      hasError: ref(false),
      modalInfoTitle: ref(''),
      modalInfoContent: ref(''),
      user: ref(null),
      company: ref(null),
      cardInvoices: ref([]),
      selectedCardInvoices: ref([]),
      totalInvoiceAmount: ref(0),
      totalSelectedInvoices: ref(0),
      isDisabledLoadMore: ref(false),
      tenantName: ref(''),
      setOpenViewInvoice,
      isOpenViewInvoice,
      isOpenPaymentHistory,
      setOpenPaymentHistory,
      invoiceSelected: ref(null),
      paymentHistories: ref(null)
    };
  },

  computed: {
    ...mapGetters([
      'listInvoices',
      'status',
      'error',
      'totalCountInvoices',
      'xeroCustomer',
      'totalUnpaidInvoices',
      'isFinanceModuleEnabled'
    ]),
    isLoadingSkeleton() {
      return this.isOpenRefLoading;
    },
    isNotFoundInvoices() {
      return (
        (!this.isOpenRefLoading && this.cardInvoices.length === 0) ||
        (!this.isOpenRefLoading && !this.isFinanceModuleEnabled)
      );
    },
    isRenderInvoices() {
      return !this.isOpenRefLoading && this.cardInvoices.length >= 1 && this.isFinanceModuleEnabled;
    },
    companyInitial() {
      return this.company?.name?.slice(0, 1).toUpperCase();
    },
    currencySymbol() {
      return this.user && this.user.country.currency_symbol;
    },
    availableCreditAmount() {
      const credit =
        Number(this.xeroCustomer?.overpayment_amount || 0) +
        Number(this.xeroCustomer?.credit_notes_amount || 0);
      return credit > 0 ? credit : 0;
    },
    unpaidAmount() {
      return Number(this.xeroCustomer?.outstanding_amount || 0);
    }
  },
  async mounted() {
    await this.initialRender();
  },
  async ionViewDidEnter() {
    if (!this.$router.options.history.state.forward) {
      await this.initialRender();
    }
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_CUSTOMER_INVOICES,
      ACTIONS.RESET_STATUS_ERROR,
      ACTIONS.LOAD_MORE_CUSTOMER_INVOICES,
      ACTIONS.GET_XERO_CUSTOMER,
      ACTIONS.GET_TOTAL_UNPAID_INVOICES,
      ACTIONS.SET_SELECTED_PAYMENT_INVOICES,
      ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED
    ]),
    async initialRender() {
      this.setOpenLoading(true);
      this.user = await this.$storage.getUser();
      this.company = await this.$storage.getSelectedCompany();
      this.tenantName = this.user.tenant.tenant.name;
      await this.checkIsFinanceModuleEnabled();
      if (!this.isFinanceModuleEnabled) {
        this.setOpenLoading(false);
        return;
      }
      await this.getXeroCustomer();
      await this.getTotalUnpaidInvoices();
      await this.getInvoices();
      this.isDisabledLoadMore = false;
      this.setOpenLoading(false);
    },
    async checkIsFinanceModuleEnabled() {
      await this[ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    },
    setCheckAllInvoices() {
      this.checkedAll = this.listInvoices.invoices.some(
        (item) => item.outstanding_amount > 0 && !item.is_pending_approval
      );
    },
    async getXeroCustomer() {
      await this[ACTIONS.GET_XERO_CUSTOMER]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    },
    async getTotalUnpaidInvoices() {
      await this[ACTIONS.GET_TOTAL_UNPAID_INVOICES]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    },
    async getInvoices() {
      await this[ACTIONS.GET_CUSTOMER_INVOICES]({
        paramsListInvoices: {
          tenantId: this.user.tenant.id,
          customerId: this.company.id,
          offset: 0,
          isIncludeTotalCount: true
        }
      }).then(() => {
        this.setCheckAllInvoices();
        this.initCardInvoices();
        this.handleSelectedInvoice();
      });
      if (!this.status) {
        this.modalInfoTitle = this.$t('failed');
        this.modalInfoContent = this.error.message;
        this.hasError = true;
        this[ACTIONS.RESET_STATUS_ERROR]();
        this.setOpenModalInfo(true);
        return;
      }
    },
    loadMore(e) {
      try {
        if (this.noLoadItems) return;
        this.noLoadItems = true;
        if (this.listInvoices.invoices.length === this.totalCountInvoices) {
          this.isDisabledLoadMore = true;
          return;
        }
        this[ACTIONS.LOAD_MORE_CUSTOMER_INVOICES]({
          paramsListInvoices: {
            tenantId: this.user.tenant.id,
            customerId: this.company.id,
            offset: this.listInvoices.invoices.length,
            isIncludeTotalCount: false
          },
          onFinish: async () => {
            e.target.complete();
            await this.getTotalUnpaidInvoices();
            await this.initCardInvoices();
            await this.handleSelectedInvoice();
          }
        });
      } catch (error) {
        this.noLoadItems = true;
      } finally {
        this.noLoadItems = false;
      }
    },
    initCardInvoices() {
      if (!this.listInvoices.invoices) return;
      this.cardInvoices = this.listInvoices.invoices.map((i) => {
        const outstandingAmount = i.outstanding_amount;
        const isPaid = outstandingAmount == 0;
        const isDisabled = isPaid || i.is_pending_approval;
        let isSelected = false;
        if (!this.checkedAll) {
          isSelected = this.cardInvoices.find((item) => item.id === i.id && item.isSelected === true)
            ?.isSelected;
        }
        return {
          id: i.id,
          customer: i.company_name,
          invoiceNumber: i.invoice_number,
          currencySymbol: this.currencySymbol,
          dueDate: i.due_date,
          invoiceDate: i.actual_delivery_date,
          amount: i.amount,
          outstandingAmount: outstandingAmount,
          creditTerm: i.credit_term,
          isPendingApproval: i.is_pending_approval,
          isOverdue: new Date(i.due_date).getTime() < new Date().getTime(),
          isPaid: isPaid,
          isSelected: !this.checkedAll ? isSelected : !isDisabled,
          isDisabled: isDisabled,
          creditTermName: i.credit_term_display_name,
          paymentTypeId: i.payment_type_id
        };
      });
    },
    thereIsUnpaidInvoices() {
      const unpaidInv = this.cardInvoices.filter((i) => !i.isPaid && !i.isPendingApproval);
      return unpaidInv.every((i) => i.isSelected);
    },
    onSelectAllInvoices(checked) {
      if (checked) {
        this.cardInvoices = this.cardInvoices.map((i) => {
          if (!i.isDisabled) i.isSelected = true;
          return i;
        });
      } else {
        if (this.thereIsUnpaidInvoices()) {
          this.cardInvoices = this.cardInvoices.map((i) => {
            i.isSelected = false;
            return i;
          });
        }
      }
    },
    async onSelectSingleInvoice(invoiceId, checked) {
      const invoiceIdx = this.cardInvoices.findIndex((i) => i.id === invoiceId);
      if (this.cardInvoices[invoiceIdx]) {
        this.cardInvoices[invoiceIdx].isSelected = checked;
        if (this.thereIsUnpaidInvoices()) this.checkedAll = true;
        else this.checkedAll = false;
        await this.handleSelectedInvoice();
      }
    },
    async handleSelectedInvoice() {
      const selectedInvoices = this.cardInvoices.filter((i) => !i.isDisabled && i.isSelected);
      const totalInvoiceAmount = selectedInvoices.reduce((acc, i) => acc + i.outstandingAmount, 0);
      const totalSelectedInvoices = selectedInvoices.length;
      this.selectedCardInvoices = [...selectedInvoices];
      this.totalInvoiceAmount = totalInvoiceAmount;
      this.totalSelectedInvoices = totalSelectedInvoices;
      await this[ACTIONS.SET_SELECTED_PAYMENT_INVOICES]({
        selectedInvoices: this.selectedCardInvoices,
        totalInvoiceAmount,
        availableCreditAmount: this.availableCreditAmount
      });
    },
    handlePayInvoices() {
      let path = '/sale/invoices/select-payment';
      this.$router.push(path);
    },
    closeModalInfo() {
      this.setOpenModalInfo(false);
      if (this.hasError) {
        this.hasError = false;
        return;
      }
    },
    async refreshAllData() {
      this.setOpenCompanySelection(false);
      await this.initialRender();
    },
    onEnterPaymentHistory(paymentHistories) {
      this.paymentHistories = paymentHistories;
      this.setOpenPaymentHistory(true);
    },
    onEnterViewInvoice(invoice) {
      this.invoiceSelected = invoice;
      this.setOpenViewInvoice(true);
    }
  }
});
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
  margin: 1rem 0;

  ion-label {
    margin-left: 7px;
    font-size: 1rem;
  }
}

.card-label {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

ion-button {
  text-transform: capitalize;
  margin-left: -1px;
  width: 100%;
}
ion-icon {
  margin-right: 0.2rem;
}
ion-text {
  font-size: 13px;
}

.image-default {
  width: 36px;
  height: 36px;
  border-radius: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.label-card {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--ion-color-text-primary-green-600);
  font-weight: 700;
}

.text-gray {
  color: #8c8c8c;
}
.top-content {
  margin-bottom: -0.6rem;
}
.box-label {
  background-color: var(--ion-color-text-error-100);
  width: fit-content;
  padding: 3px 7px;
  border-radius: 7px;
}
</style>
