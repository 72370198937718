<template>
  <ion-card class="mx-0 px-2 py-2">
    <ion-grid>
      <ion-row>
        <ion-col size="auto">
          <checkbox :disabled="invoice.isDisabled" :checked="invoice.isSelected" @onToggle="onSelection" />
        </ion-col>
        <ion-col>
          <ion-row class="mb-1fr ion-justify-content-between">
            <div
              class="label-wrapper"
              :class="{
                'background-paid': invoice.isPaid,
                'background-awaiting-payment': !invoice.isPaid
              }"
            >
              <div
                class="dot"
                :class="{
                  'dot-paid': invoice.isPaid,
                  'dot-awaiting-payment': !invoice.isPaid
                }"
              ></div>
              <ion-label
                class="label"
                :class="{
                  'label-paid': invoice.isPaid,
                  'label-awaiting-payment': !invoice.isPaid
                }"
              >
                {{ paymentStatusLabel }}</ion-label
              >
            </div>
            <ion-col v-if="invoice.isPaid" size="auto">
              <ion-label class="price-amount">{{
                priceFormatter(invoice.currencySymbol, invoice.amount)
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row v-if="invoice.isPendingApproval">
            <ion-text class="text-uppercase mb-7">
              {{ invoice.paymentTypeId !== 6 ? $t('pending_payment_approval') : $t('pending_verification') }}
            </ion-text>
          </ion-row>
          <ion-row class="ion-justify-content-between" v-if="!invoice.isPaid">
            <ion-text class="mb-7 credit-term">{{ $t('credit_term') }}: {{ creditTermValue }}</ion-text>
            <ion-label class="price-amount">{{
              priceFormatter(invoice.currencySymbol, invoice.outstandingAmount)
            }}</ion-label>
          </ion-row>
          <ion-row>
            <ion-text class="mb-7 invoice-number">
              #{{ invoice.invoiceNumber }} {{ $t('to') }} {{ invoice.tenantName }}
            </ion-text>
          </ion-row>
          <ion-row>
            <ion-text class="mb-7">{{ labelInvoiceDate }}</ion-text>
          </ion-row>
          <ion-row>
            <ion-button fill="outline" @click="$emit('onEnterViewInvoice')">
              <ion-label> {{ $t('view_invoice') }}</ion-label>
              <ion-icon color="primary" class="f-icon" :icon="chevronForwardOutline"></ion-icon>
            </ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</template>
<script>
import { apolloClient } from '@/main';
import { saleGetCustomerDetail } from '@/modules/sale/services/graphql';
import { Checkbox } from '@/modules/shared/components/invoices-payment';
import { errorAlert, priceFormatter } from '@/utils/';
import { Browser } from '@capacitor/browser';
import dayjs from 'dayjs';
import { chevronForwardOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'card-invoice',
  emits: ['onSelection', 'onEnterViewInvoice'],
  components: {
    Checkbox
  },
  props: {
    invoice: {
      type: Object,
      required: true,
      default: () => {}
    },
    customerId: {
      type: Number,
      default: null
    }
  },
  setup() {
    return {
      chevronForwardOutline,
      Browser,
      priceFormatter,
      errorAlert,
      labelInvoiceDate: ref(''),
      customerDetails: ref([])
    };
  },
  computed: {
    creditTermValue() {
      return this.invoice.creditTermName ? this.invoice.creditTermName : '-';
    },
    paymentStatusLabel() {
      return this.invoice.isPaid ? this.$t('paid') : this.$t('awaiting_payment');
    }
  },
  mounted() {
    this.labelInvoiceDate = this.$t('invoice_date', {
      invoiceDate: dayjs(this.invoice.invoiceDate).format('ddd, D MMM YYYY')
    });
    this.handleGetCustomerDetailBuyer();
  },
  methods: {
    async handleGetCustomerDetailBuyer() {
      try {
        const { data } = await apolloClient.query({
          query: saleGetCustomerDetail,
          variables: {
            id: this.customerId
          }
        });
        this.customerDetails = data.saleGetCustomerDetail;
      } catch (error) {
        this.errorAlert(error);
      }
    },
    onSelection(checked) {
      this.$emit('onSelection', this.invoice.id, checked);
    }
  }
});
</script>
<style lang="scss" scoped>
.label-wrapper {
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 0.7rem;
  padding: 2px 7px;
  margin-bottom: 10px;
  &.background-overdue {
    background-color: #fef3f2;
  }
  &.background-paid {
    background-color: #f6fef9;
  }
  &.background-awaiting-payment {
    background-color: #fdf5ed;
  }
}
.dot {
  width: 5px;
  height: 5px;
  margin-right: 5px;
  border-radius: 50%;
  &.dot-overdue {
    background-color: #f04438;
  }
  &.dot-paid {
    background-color: #039855;
  }
  &.dot-awaiting-payment {
    background-color: #eb8c31;
  }
}
.label {
  &.label-overdue {
    color: #f04438;
  }
  &.label-paid {
    color: #039855;
  }
  &.label-awaiting-payment {
    color: #eb8c31;
  }
}
ion-card {
  border-radius: none;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: -0.6rem;
}
.disabled-checkbox {
  pointer-events: none;
}
ion-button {
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  ion-label {
    text-transform: capitalize;
    font-size: 13px;
    margin-left: 5px;
  }
}
.f-icon {
  font-size: 15px;
}
.price-amount {
  font-size: 16px;
  font-weight: bold;
  color: #212121;
}
.mb-7 {
  margin-bottom: 7px;
}
.credit-term {
  color: #757575;
  font-weight: 500;
}
.invoice-number {
  color: #212121;
  font-weight: 500;
}
</style>
